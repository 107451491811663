import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
  withCredentials: true,
  headers: {
    common: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  },
});

api.interceptors.request.use((config) => {
  config.headers["Authorization"] = store.state.auth.user?.access_token;
  return config;
}, undefined);
api.interceptors.response.use(undefined, function (error) {
  console.log(error.response);
  // if(error.response.status === 401){
  //     alert("Not Authorized!!!!!!")
  // }
  return Promise.reject(error);
});

export { api };
