<template>
  <div id="app">
    <HomeLayout class="h-100" data-app />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>
import HomeLayout from "@/layout/Home";
import { api } from "@/utils/axios";

export default {
  components: { HomeLayout },
  data() {
    return {
      failureRetires: 0,
    };
  },
  mounted() {
    if (this.user && this.customResidencesWithoutOwner.length > 0) {
      this.claimCustomProperties();
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
    customResidencesWithoutOwner() {
      return this.$store.getters["residences/getCustomResidencesWithoutOwner"];
    },
  },
  methods: {
    claimCustomProperties() {
      api
        .post("/properties/claim_ownership", {
          ids: this.customResidencesWithoutOwner.map((r) => r.id),
        })
        .then(() => {
          this.$store.commit(
            "residences/updateAllCustomResidencesUserId",
            this.user.id
          );
          this.failureRetires = 0;
        })
        .catch(() => {
          if (this.failureRetires >= 3) {
            this.failureRetires = 0;
            return;
          }

          this.claimCustomProperties();

          this.failureRetires++;
        });
    },
  },
  watch: {
    user(newVal) {
      if (newVal && this.customResidencesWithoutOwner.length > 0) {
        this.claimCustomProperties();
      }
    },
    customResidencesWithoutOwner(newVal) {
      if (newVal.length > 0 && this.user) {
        this.claimCustomProperties();
      }
    },
  },
};
</script>
