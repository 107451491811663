<template>
  <div>
    <div
      id="nav"
      class="position-relative d-none d-lg-flex app-nav px-2 h-100 bg-neutral-dark align-items-center"
    >
      <router-link class="h-100 wd-10p" to="/">
        <img
          :src="require('../assets/images/logo-white.png')"
          class="img-contain p-2 ht-90p mx-wd-100p"
        />
      </router-link>
      <router-link
        class="d-flex justify-content-center"
        style="width: 12.5%"
        to="/"
      >
        <small
          class="cursor-pointer text-neutral-light font-weight-400 text-center mb-0 text-primary-hover"
          >{{ $t("navbar.findYourUnit") }}</small
        >
      </router-link>
      <router-link
        class="d-flex justify-content-center"
        style="width: 12.5%"
        to="/gallery"
      >
        <small
          class="cursor-pointer text-neutral-light font-weight-400 text-center mb-0 text-primary-hover"
        >
          {{ $t("navbar.gallery") }}</small
        >
      </router-link>

      <small
        class="cursor-pointer text-neutral-light font-weight-400 text-center mb-0 text-primary-hover"
        style="width: 12.5%"
      >
      </small>

      <user-dropdown
        v-if="user && user.access_token"
        :user="user"
        class="ms-auto me-3"
        style="width: 12.5%"
      />

      <button
        v-else
        class="small bg-neutral-dark text-primary-hover text-neutral-light font-weight-400 text-center mb-0 py-0 ms-auto me-3"
        style="width: 12.5%"
        @click="$emit('signup')"
      >
        {{ $t("navbar.signup") }}
      </button>
    </div>
    <div
      id="nav"
      class="d-flex d-lg-none flex-row justify-content-between align-items-center app-nav px-4 z-200 position-relative"
    >
      <div @click="toggleMobile">
        <div v-if="!mobileActive" class="app-nav__menu-toggle">
          <mdicon class="text-neutral-light" name="menu" size="30" />
        </div>
        <div v-if="mobileActive" class="app-nav__menu-toggle">
          <mdicon class="text-neutral-light" name="close" size="30" />
        </div>
      </div>

      <router-link to="/">
        <img
          :src="require('../assets/images/logo-white.png')"
          class="app-nav__logo"
        />
      </router-link>
    </div>
    <div
      :class="{ 'app-nav__mobile-menu-active': mobileActive }"
      class="d-flex d-lg-none app-nav__mobile-menu"
    >
      <router-link class="app-nav__link" to="/">
        <small class="text-neutral-light">Begin Your Journey</small>
      </router-link>
      <router-link class="app-nav__link" to="/gallery">
        <small class="text-neutral-light">Gallery</small>
      </router-link>
    </div>
  </div>
</template>

<script>
import UserDropdown from "@/components/UserDropdown";

export default {
  name: "AppNav",
  components: { UserDropdown },
  data: function () {
    return {
      mobileActive: false,
    };
  },
  methods: {
    toggleMobile() {
      this.mobileActive = !this.mobileActive;
    },
  },
  mounted() {
    document.querySelectorAll(".app-nav__link").forEach((el) => {
      el.onclick = () => {
        this.mobileActive = false;
      };
    });
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },
};
</script>

<style lang="scss" scoped>
.app-nav {
  width: 100%;
  display: flex;
  align-items: start;
  height: 100%;

  &__link {
    text-align: center;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__section {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 900;
    height: 100%;
    gap: 1em;
  }

  &__logo {
    height: 2rem;
  }

  &__button {
    background: var(--neutral-dark);
    color: var(--accent);

    @media (min-width: 768px) {
      background: var(--accent);
      color: var(--neutral-dark);
    }
  }

  &__button:hover {
    filter: brightness(85%);
  }

  &__mobile-menu {
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    height: calc(100% - var(--navbar-height));
    width: 100%;
    background: var(--neutral-dark);
    transform: translateY(-150%);
    transition: transform 300ms ease-in-out;
    z-index: 100;
    padding: 2rem !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;

    &-active {
      transform: translateY(0);
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--accent-dark);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
  }
}
</style>
