import Vue from "vue";
import { hdf } from "@/utils/materials/hdf";
import { porcelain } from "@/utils/materials/porcelain";
import { marble } from "@/utils/materials/marble";
import { walls } from "@/utils/materials/wallPaint";
import { engWood } from "@/utils/materials/engWood";

const state = {
  flooring: {
    hdf: hdf.map((h) => {
      return {
        ...h,
        nameAr: h.nameEn,
      };
    }),
    porcelain: porcelain.map((p) => {
      return {
        ...p,
        nameAr: p.nameEn.replace("Porcelain", "بورسيلين"),
      };
    }),
    marble: marble.map((m) => {
      return {
        ...m,
        nameAr: m.nameEn.replace("Marble", "رخام"),
      };
    }),
    engWood: engWood.map((e) => {
      return {
        ...e,
        nameAr: e.nameEn,
      };
    }),
  },
  walls: walls.map((w) => {
    return {
      ...w,
      nameAr: w.nameEn,
    };
  }),
};

const getters = {};

const mutations = {
  addItems(state, { spaces, propertyId }) {
    spaces = spaces.map((s) => {
      return {
        ...s,
        items: s.items
          .map((i) => {
            return {
              ...i,
              isSelected: false,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    });
    Vue.set(state.spaces, propertyId, spaces);
  },
};

const actions = {
  initItems() {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
