import Vue from "vue";
import { api } from "@/utils/axios";

const state = {
  residences: {},
};

const getters = {
  getResidences: (state) => state.residences,
  getCustomResidencesWithoutOwner: (_state, getters) => {
    return (
      getters.getResidences?.custom?.filter((residence) => {
        return residence.isCustom && !residence.userId;
      }) || []
    );
  },
  getResidenceByCompoundIdandResidenceId:
    (_state, getters) => (compoundId, residenceId) => {
      return getters.getResidences[compoundId]?.find((residence) => {
        return residence.id === residenceId;
      });
    },
  getResidencesByCompoundId: (_state, getters) => (compoundId) => {
    return getters.getResidences[compoundId] || [];
  },
};

const mutations = {
  addMultipleResidences(state, { residences, residentialProjectId }) {
    Vue.set(state.residences, residentialProjectId.toString(), residences);
  },
  addOneResidence(state, { residence, residentialProjectId }) {
    Vue.set(state.residences, residentialProjectId.toString(), [residence]);
  },
  updateAllCustomResidencesUserId(state, userId) {
    state.residences.custom = state.residences.custom.map((r) => {
      return {
        ...r,
        userId: userId,
      };
    });
  },
};

const actions = {
  initResidences({ commit }, residentialProjectId) {
    api
      .get(`/residential_projects/${residentialProjectId}/properties`)
      .then((response) => {
        commit("addMultipleResidences", {
          residences: response.data.data,
          residentialProjectId: residentialProjectId,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
