import Vue from "vue";
import Vuex from "vuex";
import compounds from "./compounds";
import residences from "./residences";
import packages from "./packages";
import spaces from "./spaces";
import auth from "./auth";
import locale from "./locale";
import items from "./items";
import gallery from "./gallery";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    compounds,
    residences,
    packages,
    spaces,
    auth,
    locale,
    gallery,
    items,
  },
  plugins: [vuexLocal.plugin],
});
