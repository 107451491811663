let hdf = [
  {
    nameEn: "Option 1",
    image: require("../../assets/images/flooring/hdf/20-2929-HDF.png"),
  },
  {
    nameEn: "Option 2",
    image: require("../../assets/images/flooring/hdf/25-4757-HDF DYNAMIC 8MM.png"),
  },
  {
    nameEn: "Option 3",
    image: require("../../assets/images/flooring/hdf/30-3004-HDF EXQUIS 8MM.png"),
  },
  {
    nameEn: "Option 4",
    image: require("../../assets/images/flooring/hdf/35-2805-HDF EXQUIS 8MM.jpg"),
  },
  {
    nameEn: "Option 5",
    image: require("../../assets/images/flooring/hdf/40-3664-HDF EXQUIS PLUS.png"),
  },
  {
    nameEn: "Option 6",
    image: require("../../assets/images/flooring/hdf/45-6019-HDF EXQUIS PLUS 8MM.png"),
  },
  {
    nameEn: "Option 7",
    image: require("../../assets/images/flooring/hdf/2804-HDF EXQUIS 8MM.jpg"),
  },
  {
    nameEn: "Option 8",
    image: require("../../assets/images/flooring/hdf/3044-HDF EXQUIST PLUS 8MM.png"),
  },
  {
    nameEn: "Option 9",
    image: require("../../assets/images/flooring/hdf/3126-HDF.png"),
  },
  {
    nameEn: "Option 10",
    image: require("../../assets/images/flooring/hdf/3531-HDF.png"),
  },
  {
    nameEn: "Option 11",
    image: require("../../assets/images/flooring/hdf/4175-HDF SUPERIOR CATWALK.jpg"),
  },
  {
    nameEn: "Option 12",
    image: require("../../assets/images/flooring/hdf/4983-HDF EXQUIS PLUS 8MM.png"),
  },
];

export { hdf };
