<template>
  <transition name="fade">
    <div v-if="show" id="modal" class="modal">
      <div class="modal__body">
        <div v-if="!hideClose" class="modal__sticky-top">
          <v-icon class="p-0 modal__close mb-5 mb-md-0" @click="closeClicked">
            mdi-close
          </v-icon>
        </div>

        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PopupModal",
  props: ["show", "hideClose"],
  methods: {
    setFullHeight() {
      this.$nextTick(() => {
        document.getElementById(
          "modal"
        ).style.height = `${window.innerHeight}px`;

        window.addEventListener("resize", () => {
          if (this.show) {
            document.getElementById(
              "modal"
            ).style.height = `${window.innerHeight}px`;
          }
        });
      });
    },
    closeClicked() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.show) {
      this.setFullHeight();
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.setFullHeight();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 100ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  width: 100%;
  display: block;

  &__body {
    opacity: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 0.5rem;
    position: relative;
  }

  &__sticky-top {
    width: 100%;
    background: rgba(0, 0, 0, 0.93);
    position: sticky;
    top: 0;
  }

  &__close {
    font-size: 3rem;
    color: var(--neutral-light);
    height: fit-content;
    display: block;
    margin-left: auto;
  }
}
</style>
