<template>
  <div
    v-click-outside="hide"
    class="user-dropdown d-flex justify-content-between"
  >
    <div
      class="user-dropdown__icons d-flex justify-content-center align-items-center cursor-pointer w-100"
      @click="isExpanded = !isExpanded"
    >
      <small
        class="me-2 overflow-hidden"
        style="white-space: nowrap; text-overflow: ellipsis"
        >{{ user.name }}</small
      >
      <mdicon class="ms-auto" name="account" size="20" />
      <mdicon name="chevron-down" size="20" />
    </div>
    <div
      v-if="isExpanded"
      class="user-dropdown__menu d-flex flex-column bg-neutral-dark"
    >
      <small class="px-2 text-neutral-light py-2 cursor-pointer" @click="logOut"
        >Log out</small
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDropdown",
  props: ["user"],
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    hide() {
      this.isExpanded = false;
    },
    logOut() {
      this.$store.dispatch("auth/logOut");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown {
  position: relative;
  color: var(--neutral-light);

  &__icons:hover {
    color: var(--primary);
  }

  &__menu {
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
    left: 0;
    border: 1px solid var(--neutral-light);
    border-radius: 0.2rem;

    &__item {
      border-bottom: 0.5px solid var(--neutral-light);
    }
  }
}
</style>
