<template>
  <popup-modal :hide-close="true" :show="showOverlay">
    <div
      :class="{ error: !phoneValid }"
      class="sign-up w-100 h-100 d-flex flex-row justify-content-center align-items-center"
    >
      <div
        class="p-3 p-md-4 bg-neutral-light wd-100p wd-md-30p d-flex flex-column justify-content-center align-items-center"
        style="border-radius: 0.5rem"
      >
        <div v-if="!hideClose" class="ms-auto" @click="closed">
          <mdicon
            class="text-neutral-dark cursor-pointer"
            name="close"
            size="30"
          />
        </div>
        <FormulateForm v-model="values" class="w-100" @submit="signUp">
          <FormulateInput
            class="w-100"
            error-behavior="blur"
            input-class="w-100 px-2"
            label="Full name"
            name="name"
            type="text"
            validation="bail|required|"
            validation-name="Name"
          />
          <FormulateInput
            :errors="emailErrors"
            class="w-100 mt-2"
            error-behavior="blur"
            input-class="w-100 px-2"
            label="Email"
            name="email"
            type="email"
            validation="bail|required|email|"
            validation-name="Email"
          />
          <vue-tel-input
            class="mt-3 w-100"
            defaultCountry="eg"
            @input="setPhoneData"
          ></vue-tel-input>

          <small
            v-if="!phoneValid"
            class="text-capitalize mt-3"
            style="color: darkred"
            >Please make sure your phone number is valid</small
          >

          <div v-if="formErrors.length > 0" class="mt-3">
            <small
              v-for="error in formErrors"
              :key="error"
              class="text-capitalize d-block mt-1"
              style="color: darkred"
              >{{ error }}</small
            >
          </div>

          <input
            class="rounded-button bg-primary text-neutral-dark font-weight-500 px-5 mt-3 mx-auto"
            type="submit"
            value="Sign up"
          />
        </FormulateForm>

        <h6
          class="text-neutral-dark mt-3 cursor-pointer"
          @click="$emit('signin')"
        >
          Sign in
        </h6>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/Modal";
import { VueTelInput } from "vue-tel-input";

export default {
  name: "SignupModal",
  components: { PopupModal, VueTelInput },
  props: ["showOverlay", "hideClose", "hideLoginClose"],
  data() {
    return {
      phoneData: null,
      emailErrors: [],
      formErrors: [],
      values: {},
    };
  },
  methods: {
    closed() {
      this.$emit("closed");
    },
    signUp() {
      if (!this.phoneValid) {
        return;
      }

      this.$store.dispatch("auth/signUp", {
        ...this.values,
        phone: this.phoneData.number,
        onSuccess: () => {
          this.$emit("closed");
        },
        onFailure: (messages) => {
          this.formErrors = [];
          this.emailErrors = [];

          if (messages.email) {
            this.emailErrors = messages.email.map((m) => `Email ${m}`);
          } else {
            Object.keys(messages)
              .filter((k) => k !== "email")
              .forEach((k) => {
                this.formErrors.push(...messages[k].map((m) => `${k} ${m}`));
              });
          }
        },
      });
    },
    setPhoneData(_, phoneObject) {
      this.phoneData = phoneObject;
    },
  },
  computed: {
    phoneValid() {
      if (!this.phoneData) return true;

      return this.phoneData.valid;
    },
  },
};
</script>

<style lang="scss">
.formulate-form-errors,
.formulate-input-errors {
  color: darkred;
  font-size: 14px;
  padding-left: 0;
  margin-bottom: 0;

  list-style-type: none !important;
}

.vue-tel-input:focus-within {
  box-shadow: none !important;
}

.error {
  .vue-tel-input {
    border-color: darkred !important;
  }
}
</style>
