<template>
  <div class="w-100">
    <vue-tel-input
      :value="value"
      class="w-100"
      defaultCountry="eg"
      @input="setPhoneData"
    ></vue-tel-input>

    <small
      v-if="!phoneValid"
      class="text-capitalize mt-3"
      style="color: darkred"
      >Please make sure your phone number is valid</small
    >
  </div>
</template>

<script>
export default {
  name: "sh-tel-input",
  props: ["value"],
  data() {
    return {
      phoneData: null,
    };
  },
  methods: {
    setPhoneData(_, phoneObject) {
      this.phoneData = phoneObject;
      this.$emit("input", {
        number: this.phoneData.number,
        valid: this.phoneValid,
      });
    },
  },
  computed: {
    phoneValid() {
      if (!this.phoneData) return true;

      return this.phoneData.valid;
    },
  },
};
</script>

<style scoped></style>
