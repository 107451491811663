import Vue from "vue";

import { api } from "@/utils/axios";

const state = {
  compounds: [],
};

const getters = {
  getCompounds: (state) => state.compounds,
  getCompoundById: (_state, getters) => (id) => {
    return getters.getCompounds.find((compound) => {
      return compound.id === parseInt(id);
    });
  },
};

const mutations = {
  addMultipleCompounds(state, compounds) {
    Vue.set(state, "compounds", compounds);
  },
};

const actions = {
  initCompounds({ commit }) {
    api.get(`/residential_projects`).then((response) => {
      commit("addMultipleCompounds", response.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
