<template>
  <main
    id="root"
    :class="{ 'body--ar': $i18n.locale === 'ar' }"
    class="body--light"
  >
    <div id="parent-container" class="parent-container d-flex flex-column">
      <app-nav
        :class="{ 'parent-container__header-hidden': !showNavbar }"
        class="parent-container__header px-0"
        @login="showLogin = true"
        @signup="showSignup = true"
      />

      <div id="main" class="parent-container__main">
        <transition mode="out-in" name="fade">
          <router-view
            @signup="
              hideLoginClose = true;
              showSignup = true;
            "
          >
          </router-view>
        </transition>

        <footer class="parent-container__footer px-4 px-md-5">
          <router-link to="/">
            <img
              :src="require('../assets/images/logo-black.png')"
              class="parent-container__footer__logo img-contain"
              style="height: 2rem"
            />
          </router-link>
          <div class="d-flex parent-container__footer__location mt-4 mt-md-0">
            <a
              class="d-flex"
              href="https://goo.gl/maps/RDntyrYNjBd176ex9"
              target="_blank"
            >
              <mdicon class="text-neutral-dark" name="map-marker" size="30" />
              <h6 class="mb-0 text-neutral-dark">
                The Industrial Zone,<br />Industrial Area,<br />New Cairo 3
                Cairo Governorate
              </h6>
            </a>
          </div>
          <div
            class="parent-container__footer__contactus mt-4 mt-md-0 d-flex align-items-end"
          >
            <h4 class="mb-0 font-weight-500 text-neutral-dark">Contact Us</h4>
          </div>

          <div class="parent-container__footer__social mt-3 mt-md-0">
            <a href="mailto:info@shatablee.com">
              <h6 class="mb-2 text-neutral-dark">Email: info@shatablee.com</h6>
            </a>
            <a href="tel:15994">
              <h6 class="mb-2 text-neutral-dark">Hotline: 15994</h6>
            </a>
            <div class="d-flex flex-wrap align-items-end gap-3">
              <a href="https://www.facebook.com/shatablee" target="_blank">
                <mdicon class="text-neutral-dark" name="facebook" size="30" />
              </a>
              <a href="https://www.instagram.com/shatablee" target="_blank">
                <mdicon class="text-neutral-dark" name="instagram" size="30" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC8IItiBQUt683fr8ew4HCqw"
                target="_blank"
              >
                <mdicon class="text-neutral-dark" name="youtube" size="30" />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <login-modal
      :hide-close="hideLoginClose"
      :showOverlay="showLogin"
      @closed="showLogin = false"
      @signup="
        showLogin = false;
        showSignup = true;
      "
    />
    <signup-modal
      :hide-close="hideLoginClose"
      :show-overlay="showSignup"
      @closed="showSignup = false"
      @signin="
        showSignup = false;
        showLogin = true;
        hideLoginClose = true;
      "
    />
  </main>
</template>

<script>
import AppNav from "@/components/AppNav";
import LoginModal from "@/components/LoginModal";
import SignupModal from "@/components/SignupModal";

export default {
  name: "HomeLayout",
  components: { SignupModal, LoginModal, AppNav },
  mounted() {
    const html = document.documentElement;
    html.setAttribute("lang", "en");
    this.$store.commit("locale/setLocale", this.locale);
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  data: function () {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      isScrolling: false,
      showLogin: false,
      showSignup: false,
      hideLoginClose: false,
    };
  },
  methods: {
    onScroll() {
      if (document.body.clientWidth < 768) {
        return;
      }

      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition < 50) {
        this.showNavbar = true;
        return;
      }

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 20) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
  watch: {
    "$i18n.locale": function (newVal) {
      const html = document.documentElement;
      html.setAttribute("lang", newVal);
      html.setAttribute("dir", newVal === "ar" ? "rtl" : "ltr");
    },
  },
  computed: {
    locale() {
      return this.$store.getters["locale/getLocale"];
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 190ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.parent-container {
  width: 100%;
  overflow-x: hidden;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    grid-area: header;
    background: var(--neutral-dark);
    height: 50px;
    position: fixed;
    width: 100%;
    top: 0;
    transition: transform 150ms linear;
    z-index: 300;
    max-width: 100%;

    padding: 1rem 1rem 0.5rem;

    @media (min-width: 768px) {
      padding: 0 2rem;
      gap: 1rem !important;
    }

    &-hidden {
      transform: translateY(-120%);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-area: main;
  }

  &__footer {
    display: grid;
    padding: 1rem 1rem;
    background-color: var(--neutral);
    border-top: 2px solid var(--neutral-dark);
    color: white !important;
    max-width: 1600px;
    width: 100%;
    grid-column-gap: 1rem;
    grid-template-areas:
      "logo logo"
      "location contactus"
      "location social";

    @media (min-width: 768px) {
      grid-template-areas:
        "logo contactus"
        "location social";
      grid-template-columns: max-content max-content;

      row-gap: 1rem;
      column-gap: 7rem;
    }

    &__logo {
      grid-area: logo;
    }

    &__contactus {
      grid-area: contactus;
    }

    &__location {
      grid-area: location;
    }

    &__social {
      grid-area: social;
    }
  }
}

.ms-md-lg {
  @media (min-width: 768px) {
    margin-left: 10rem !important;
  }
}
</style>
