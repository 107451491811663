let engWood = [
  {
    nameEn: "Option 1",
    image: require("../../assets/images/flooring/engWood/1.jpeg"),
  },
  {
    nameEn: "Option 2",
    image: require("../../assets/images/flooring/engWood/2.jpeg"),
  },
  {
    nameEn: "Option 3",
    image: require("../../assets/images/flooring/engWood/3.jpeg"),
  },
  {
    nameEn: "Option 4",
    image: require("../../assets/images/flooring/engWood/4.jpeg"),
  },
  {
    nameEn: "Option 5",
    image: require("../../assets/images/flooring/engWood/5.jpeg"),
  },
  {
    nameEn: "Option 6",
    image: require("../../assets/images/flooring/engWood/7.jpeg"),
  },
  {
    nameEn: "Option 7",
    image: require("../../assets/images/flooring/engWood/8.jpeg"),
  },
  {
    nameEn: "Option 8",
    image: require("../../assets/images/flooring/engWood/9.jpeg"),
  },
];

export { engWood };
