import { api } from "@/utils/axios";
import axios from "axios";

const state = {
  user: {},
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  removeUser(state) {
    state.user = {};
  },
};

const actions = {
  async signIn({ commit }, { email, phoneNumber, onSuccess, onFailure }) {
    axios
      .post(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/users/sign_in`,
        {
          user: {
            email: email,
            phone_number: phoneNumber,
          },
        },
        {
          withCredentials: true,
          headers: {
            Authorization: null,
          },
        }
      )
      .then((response) => {
        commit("setUser", {
          ...response.data,
          access_token: response.headers["authorization"],
        });
        onSuccess();
      })
      .catch(() => {
        onFailure();
      });
  },
  signUp({ commit }, { name, phone, email, password, onSuccess, onFailure }) {
    axios
      .post(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/users`,
        {
          user: {
            email: email,
            password: password,
            name: name,
            phone_number: phone,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        commit("setUser", {
          ...response.data,
          access_token: response.headers["authorization"],
        });
        onSuccess();
      })
      .catch((error) => {
        if (error.response) {
          onFailure(error.response.data);
          return;
        }
        onFailure([]);
      });
  },
  logOut({ commit }) {
    api
      .delete(`/users/sign_out`, {
        withCredentials: true,
      })
      .then(() => {
        commit("removeUser");
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
