import { api } from "@/utils/axios";

const state = {
  categories: [],
};

const getters = {
  getCategories: (state) => state.categories,
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
};

const actions = {
  initCategories({ commit }) {
    api.get(`/gallery`).then((response) => {
      commit("setCategories", response.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
