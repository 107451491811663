<template>
  <div class="h-100 w-100">
    <div
      class="d-flex justify-content-center align-items-center order-page h-100 w-100"
    >
      <img
        :src="require('../../assets/images/orders-bg.jpeg')"
        class="order-page__img"
      />

      <div
        class="order-page__content d-flex flex-column justify-content-center align-items-center"
      >
        <div
          class="wd-90p wd-md-70p d-flex flex-column align-items-center pb-5"
          style="background: rgba(0, 0, 0, 0.6); border-radius: 1rem"
        >
          <h4 class="text-neutral-light text-center mt-5 mx-5">
            <span class="text-primary">Congratulations!</span><br />
          </h4>
          <small class="text-neutral-light text-center"
            >Your order is under review.<br />
            One of our agents will contact you shortly.
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSConfetti from "js-confetti";

export default {
  name: "OrderPage",
  mounted() {
    const jsConfetti = new JSConfetti();

    jsConfetti.addConfetti({
      confettiColors: ["#FFDD00"],
    });
  },
};
</script>

<style lang="scss" scoped>
.order-page {
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    border-radius: 2rem;
  }

  &__logo {
    position: absolute;
    content: "";
    height: 3rem;
    top: 0;
    right: 0;
    margin-top: 1rem;
    margin-right: 1rem;
    object-fit: cover;
    z-index: 2;
  }

  &__img {
    z-index: -1;
    object-fit: cover;
    width: 100%;
  }

  &__content {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (min-width: 768px) {
      border-radius: 2rem;
    }
  }
}
</style>
