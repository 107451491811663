import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import clickOutside from "./directives/click-outside";
import mdiVue from "mdi-vue/v2";
import {
  mdiAccount,
  mdiChevronDown,
  mdiChevronUp,
  mdiChevronRight,
  mdiChevronLeft,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
  mdiClose,
  mdiMenu,
  mdiCalculator,
  mdiCheck,
  mdiMapMarker,
  mdiFacebook,
  mdiYoutube,
  mdiInstagram,
  mdiPlus,
} from "@mdi/js";
import vSelect from "vue-select";
import "./assets/styles/application.scss";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueFormulate from "@braid/vue-formulate";
import FormTelInput from "@/components/ShTelInput";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.use(VueTelInput);

Vue.component("field-tel-input", FormTelInput);

Vue.use(
  VueGtag,
  {
    config: { id: "G-WE1LDDY5XN" },
  },
  router
);

Vue.use(VueFormulate);
Vue.use(LottieVuePlayer);

Vue.config.productionTip = false;

Vue.directive("click-outside", clickOutside);
Vue.use(mdiVue, {
  icons: {
    mdiAccount,
    mdiChevronDown,
    mdiChevronUp,
    mdiChevronRight,
    mdiChevronLeft,
    mdiChevronDoubleRight,
    mdiChevronDoubleLeft,
    mdiClose,
    mdiMenu,
    mdiCalculator,
    mdiCheck,
    mdiMapMarker,
    mdiFacebook,
    mdiYoutube,
    mdiInstagram,
    mdiPlus,
  },
});

Vue.component("v-select", vSelect);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
