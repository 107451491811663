import Vue from "vue";
import i18n from "@/i18n";

const state = {
  locale: "en",
};

const getters = {
  getLocale: (state) => state.locale,
};

const mutations = {
  toggleLocale(state) {
    let newLocale = state.locale === "en" ? "ar" : "en";
    i18n.locale = newLocale;
    Vue.set(state, "locale", newLocale);
  },
  setLocale(state, newLocale) {
    i18n.locale = newLocale;
    Vue.set(state, "locale", newLocale);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
