import Vue from "vue";
import VueRouter from "vue-router";
import OrderPage from "@/views/User/OrderPage";
import TrackYourOrder from "@/views/User/TrackYourOrder";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/CompoundsPage.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/GalleryPage"),
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import("../views/User/MyAccountPage"),
    children: [
      { name: "orders", path: "/orders", component: OrderPage },
      { name: "track", path: "/track", component: TrackYourOrder },
    ],
  },
  {
    path: "/compounds/:compoundId/residences",
    name: "residences",
    component: () => import("../views/ResidenceTypeSelect.vue"),
  },
  {
    path: "/compounds/:compoundId/residences/:residenceId/packages",
    name: "packages",
    component: () => import("../views/PackagesPage"),
  },
  {
    path: "/compounds/:compoundId/residences/:residenceId/packages/:packageId/customize",
    name: "customize",
    component: () => import("../views/CustomizePage"),
  },
  {
    path: "/compounds/:compoundId/residences/:residenceId/packages/:packageId/concept",
    name: "concept",
    component: () => import("../views/ConceptPage"),
  },
  { path: "*", redirect: { name: "home" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document
      .querySelectorAll(".parent-container__main, .parent-container__sidebar")
      .forEach((el) => {
        el.scroll({
          top: 0,
          behavior: "smooth",
        });
      });
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let compoundId = to.params.compoundId?.toString();
  let compoundsIds = store.state.compounds.compounds.map((c) =>
    c.id.toString()
  );

  if (compoundId === "custom") {
    next();
    return;
  }

  if (compoundId && !compoundsIds.includes(compoundId)) {
    next({ path: "/", replace: true });
  } else {
    next();
  }
});

export default router;
