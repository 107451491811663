import Vue from "vue";
import { api } from "@/utils/axios";
import uniqby from "lodash.uniqby";

const state = {
  spaces: {},
};

const getters = {
  getSpaces: (state) => state.spaces,
  getSpacesByResidenceId: (_state, getters) => (residenceId) => {
    return getters.getSpaces[residenceId] || [];
  },
};

const mutations = {
  addSpaces(state, { spaces, propertyId }) {
    spaces = spaces.map((s) => {
      return {
        ...s,
        selectedItems: {
          flooringMaterial: null,
          flooring: null,
          walls: null,
          acType: null,
          includeAcUnit: false,
        },
        items: s.items
          .map((i) => {
            return {
              ...i,
              isSelected: false,
            };
          })
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              ignorePunctuation: true,
              numeric: true,
            })
          ),
      };
    });

    spaces.forEach((s) => {
      if (s.sections.length === 0) {
        s.groups = [];
        return;
      }

      let groups = uniqby(
        s.sections.map((sec) => sec.group),
        "id"
      );
      groups = groups.map((g) => {
        return {
          ...g,
          sections: [],
          selectedSectionOptionId: null,
          selectedSectionId: null,
        };
      });

      s.sections.forEach((sec) => {
        let group = groups.find((g) => g.id === sec.group.id);

        group.sections.push(sec);
      });

      groups.forEach((g) => {
        g.selectedSectionOptionId = g.sections[0].options.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            ignorePunctuation: true,
            numeric: true,
          })
        )[0].id;
        g.selectedSectionId = g.sections.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            ignorePunctuation: true,
            numeric: true,
          })
        )[0].id;
      });

      s.groups = groups;
    });

    Vue.set(state.spaces, propertyId, spaces);
  },
  setSpaceItem(state, { spaceId, propertyId, item, value }) {
    let spaceItems = state.spaces[propertyId].find(
      (s) => s.id === spaceId
    ).selectedItems;

    Vue.set(spaceItems, item, value);
  },
};

const actions = {
  initSpaces({ commit }, { propertyId, packageId }) {
    return new Promise((resolve) => {
      api
        .get(`/properties/${propertyId}/spaces?package_id=${packageId}`)
        .then((response) => {
          commit("addSpaces", {
            spaces: response.data.data,
            propertyId: propertyId,
          });

          resolve();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
