let porcelain = [
  {
    nameEn: "Porcelain 1",
    image: require("../../assets/images/flooring/porcelain/10-por porto grey.jpeg"),
  },
  {
    nameEn: "Porcelain 2",
    image: require("../../assets/images/flooring/porcelain/12-viola grey.jpeg"),
  },
];

export { porcelain };
