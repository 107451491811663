let walls = [
  {
    nameEn: "Antique Beige",
    image: require("../../assets/images/walls/ANTIQUE WHITE 1016.jpg"),
  },
  {
    nameEn: "Cotton Ball",
    image: require("../../assets/images/walls/COTTON BALL 1453.jpg"),
  },
  {
    nameEn: "Chino",
    image: require("../../assets/images/walls/CHINO 0553.jpg"),
  },
  {
    nameEn: "Egg White",
    image: require("../../assets/images/walls/EGG WHITE 1001.jpg"),
  },
  {
    nameEn: "Iron Grey",
    image: require("../../assets/images/walls/IRON GREY 1032.jpg"),
  },
  {
    nameEn: "Jazz Grey",
    image: require("../../assets/images/walls/JAZZ GREY 9930.jpg"),
  },
  {
    nameEn: "Macchiato",
    image: require("../../assets/images/walls/MACCIATTO 1359.jpg"),
  },
  {
    nameEn: "Sable Stone",
    image: require("../../assets/images/walls/SABLE STONE 1034.jpg"),
  },
  {
    nameEn: "Vanilla Latte",
    image: require("../../assets/images/walls/VANILLA LATTE 1519.jpg"),
  },
  {
    nameEn: "Washed Linen",
    image: require("../../assets/images/walls/WASHED LINEN 10679.jpg"),
  },
  {
    nameEn: "White Linen",
    image: require("../../assets/images/walls/WHITE LINEN 10182.jpg"),
  },
];

export { walls };
