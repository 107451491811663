<template>
  <div class="h-100 w-100 p-5 ps-0">
    <div
      class="d-flex justify-content-center align-items-end track-order h-100 w-100"
    >
      <img
        :src="require('../../assets/images/orders-bg.jpeg')"
        class="track-order__img"
      />
      <img
        :src="require('../../assets/images/logo-white.png')"
        class="track-order__logo"
      />

      <div
        class="d-flex flex-column justify-content-center align-items-center track-order__content px-5 pt-3 pb-4 mb-5"
      >
        <h3></h3>
        <h3 class="text-neutral-light text-center mt-auto mx-5">
          <span class="text-primary"></span><br />
          <h5 class="mt-3">
            Tracking.<br />
            One of our agents will contact you shortly.
          </h5>
        </h3>

        <div class="d-flex justify-content-center w-100 gap-2 mt-3">
          <button class="track-order__button">
            <small>Book an appointment</small>
          </button>
          <button class="track-order__button">
            <small>Track your order</small>
          </button>
          <a href="tel:15994">
            <button class="track-order__button">
              <small>Contact us</small>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackYourOrder",
};
</script>

<style lang="scss" scoped>
.track-order {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;

  &__logo {
    position: absolute;
    content: "";
    height: 3rem;
    top: 0;
    right: 0;
    margin-top: 1rem;
    margin-right: 1rem;
    object-fit: cover;
    z-index: 2;
  }

  &__img {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }

  &__content {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 2rem;
  }

  &__button {
    border-radius: 20px;
    border: 2px solid var(--neutral-light);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: var(--neutral-light);
    width: 13rem;

    &:hover {
      background: var(--primary);
      color: var(--neutral-dark);
      border-color: var(--primary);
    }
  }
}
</style>
