<template>
  <popup-modal :hide-close="true" :show="showOverlay">
    <div
      class="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
    >
      <div
        class="p-3 p-md-4 bg-neutral-light wd-100p wd-md-30p d-flex flex-column justify-content-center align-items-center"
        style="border-radius: 0.5rem"
      >
        <div v-if="!hideClose" class="align-self-end" @click="closed">
          <mdicon
            class="text-neutral-dark cursor-pointer"
            name="close"
            size="30"
          />
        </div>

        <FormulateInput
          v-model="email"
          class="w-100"
          error-behavior="live"
          input-class="w-100 px-2"
          label="Email"
          type="email"
          validation="bail|required|email|"
          validation-name="Email"
        />

        <vue-tel-input
          class="mt-3 w-100"
          defaultCountry="eg"
          @input="setPhoneData"
        ></vue-tel-input>

        <small
          v-if="!phoneValid"
          class="text-capitalize mt-1 me-auto"
          style="color: darkred"
          >Please make sure your phone number is valid</small
        >

        <small v-if="invalid" class="text-error me-auto"
          >Email or Phone Number is invalid</small
        >

        <button class="bg-primary text-neural-light px-5 mt-3" @click="login">
          <h6 class="mb-0">Login</h6>
        </button>

        <h6
          class="text-neutral-dark mt-3 cursor-pointer"
          @click="$emit('signup')"
        >
          Dont' have an account? Sign up
        </h6>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/Modal";

export default {
  name: "LoginModal",
  components: { PopupModal },
  props: ["showOverlay", "hideClose"],
  data() {
    return {
      email: "",
      invalid: false,
      phoneData: null,
    };
  },
  methods: {
    closed() {
      this.$emit("closed");
    },
    login() {
      this.$store.dispatch("auth/signIn", {
        email: this.email,
        phoneNumber: this.phoneData.number,
        onSuccess: () => {
          this.$emit("closed");
        },
        onFailure: () => {
          this.invalid = true;
        },
      });
    },
    setPhoneData(_, phoneObject) {
      this.phoneData = phoneObject;
    },
  },
  computed: {
    phoneValid() {
      if (!this.phoneData) return true;

      return this.phoneData.valid;
    },
  },
};
</script>

<style lang="scss">
.formulate-form-errors,
.formulate-input-errors {
  color: darkred;
  font-size: 14px;
  padding-left: 0;
  margin-bottom: 0;

  list-style-type: none !important;
}
</style>
