let marble = [
  {
    nameEn: "Bitcino Classic",
    image: require("../../assets/images/flooring/marble/bitcino-classic.jpeg"),
  },
  {
    nameEn: "Carrara White",
    image: require("../../assets/images/flooring/marble/carrara-white.jpeg"),
  },
];

export { marble };
