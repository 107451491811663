import Vue from "vue";
import { api } from "@/utils/axios";

const state = {
  packages: {},
};

const getters = {
  getPackages: (state) => state.packages,
  getPackageUsingResidenceIdAndPackageId:
    (_state, getters) => (residenceId, packageId) => {
      return (
        getters.getPackages[residenceId]?.find((p) => p.id == packageId) || []
      );
    },
  getPackagesUsingResidenceId: (_state, getters) => (residenceId) => {
    return getters.getPackages[residenceId] || [];
  },
};

const mutations = {
  addMultiplePackages(state, { packages, propertyId }) {
    Vue.set(
      state.packages,
      propertyId,
      packages.sort((p1, p2) => p1.price - p2.price)
    );
  },
};

const actions = {
  initPackages({ commit }, propertyId) {
    api.get(`/properties/${propertyId}/packages`).then((response) => {
      let mappedResponse = response.data.data.map((p) => {
        return {
          id: p.id,
          nameEn: p.nameEn,
          nameAr: p.nameAr,
          price: p.price,
          daysToDeliver: p.daysToDeliver,
        };
      });

      commit("addMultiplePackages", {
        packages: mappedResponse,
        propertyId: propertyId,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
